<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 电影票订单
 * @Date: 2020-12-30 19:04:47
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-20 22:25:02
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/cinematicket.vue
-->
<template>
  <div class="user_content">
    <van-tabs v-model="type" style="border-top: 1px solid #eee;" @click="tabkey">
      <van-tab v-for="(item,index) in tablist" :key="index" :title="item.name" />
    </van-tabs>
    <skeleton v-show="loading" type="order" />
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <!-- <div id="dataList" v-show></div> -->
        <div v-if="!list.length && !loading">
          <van-empty
            image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
            description="暂无数据"
          />
        </div>
        <div v-else class="orderlist">
          <ul>
            <li v-for="(item,index) in list" :key="index" @click="goFilmDetail(item)">
              <div class="sptitle">
                <span>{{ item.cinema_name }}</span>
                <span
                  :style="{color: item.order_status_name === '已出票' || item.order_status_name === '出票中'?'#E43F44':'#999999'}"
                >{{ item.order_status_name }}</span>
              </div>
              <div class="spcenter">
                <div>
                  <img :src="item.cinema_pic" alt="">
                </div>
                <div>
                  <p>片名：{{ item.film_name }}</p>
                  <p>场次：{{ item.show_time }}</p>
                  <p>座次：{{ item.seat_info }}</p>
                  <p>价格：<span>¥{{ item.pay_amount }}</span></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </mescroll>
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Tab, Tabs } from 'vant'

Vue.use(Tab).use(Tabs).use(Empty)
import {
  getOrderListNew
} from '@/services/comeOn.js'
import mescroll from '@/component/fresh_scroll/mescroll'
import skeleton from '@/component/skeleton/index'

export default {
  components: {
    mescroll,
    skeleton
  },
  data() {
    return {
      mid: '',
      nolist: false,
      type: 0,
      page: 1,
      pageSize: 10,
      list: [],
      tablist: [
        { name: '全部' },
        { name: '出票中' },
        { name: '已出票' },
        { name: '已取消' },
        { name: '已完成' }
      ],
      mescrollDown: {
        use: true,
        bottom: '0',
        top: '50px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true
        }
      }
    }
  },
  created() {
    this.mid = localStorage.getItem('uid')
  },
  methods: {
    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      this.getinfo(page.num, page.size, (arr) => {
        if (page.num === 1) this.list = []
        this.list = this.list.concat(arr)
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length)
        })
      }, () => {
        mescroll.endErr()
      })
    },
    getinfo(pageNum, pageSize, successCallback, errorCallback) {
      const prom = {
        mid: window.localStorage.getItem('uid'),
        orderType: 1010,
        page: this.page,
        pageSize: pageSize,
        status: this.type
      }
      getOrderListNew(prom).then(res => {
        this.loading = true
        if (Number(res.code) === 200) {
          successCallback(res.data.list)
          this.page++
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    tabkey() {
      this.list = []
      this.loading = true
      this.page = 1
      this.mescroll.resetUpScroll()
    },
    goFilmDetail(item) {
      this.$router.push({
        path: '/filmOrderDetail',
        query: {
          orderNo: item.orderNo
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .orderlist {
    & > ul li {
      background: #FFFFFF;
      margin: 13px;
      padding: 10px;

      .sptitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span:nth-child(1) {
          color: #333333;
          font-size: 14px;
        }

        & > span:nth-child(2) {
          color: #999999;
          font-size: 13px;
        }
      }

      .spcenter {
        margin: 10px 0 0;
        display: flex;

        & > div:nth-child(1) {
          border-radius: 3px;
          width: 70px;
          height: 70px;
          background: #eee;
          margin-right: 10px;

          & > img {
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }
        }

        & > div:nth-child(2) {
          p {
            width: 100%;
            color: #666666;
            text-align: left;
            line-height: 20px;
            font-size: 12px;
          }

          span {
            color: #E43F44;
          }
        }
      }

      .spfoot {
        border-top: 1px solid #F2F2F2;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;

        .s {
          border-radius: 15px;
          padding: 5px 10px;
          margin-right: 10px;
        }

        .st {
          color: #999999;
          border: 1px solid #999999;
        }

        .sc {
          color: #E43F44;
          border: 1px solid #E43F44;
        }
      }
    }
  }
}
</style>
